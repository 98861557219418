import React, { Suspense }                        from "react"
import { Helmet }                                 from "react-helmet"
import "../scss/main.scss"
import Header                                     from "./Header"
import styles                                     from "./scss/app.module.scss"
import CssModules                                 from "react-css-modules"
import Footer                                     from "./Footer"
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import PageLoading                                from "./PageLoading"
import routes, { getMenuRoutes }                  from "../routes"
import Grid, { Pane }                             from "../components/Grid"

const App = () => <>
  <Helmet titleTemplate="%s | Badge Trail"/>
  <a className={ styles.mainContentLink } href="#main-content">Go to main content</a>
  <Router>
    <Grid areas={ [ "header", "body", "footer" ] }
          rowTemplate="min-content auto 300px"
    >
      <Header routes={ getMenuRoutes() }/>
      <Pane id="main-content" className={ styles.mainContent }>
        <Suspense fallback={ PageLoading }>
          <Switch>
            {
              routes.map(({ name, path, component, exact }) => <Route exact={ exact }
                                                                      key={ name }
                                                                      path={ path }
                                                                      component={ component }
              />)
            }
          </Switch>
        </Suspense>
      </Pane>
      <Footer/>
    </Grid>
  </Router>
</>

export default CssModules(App)