import React      from "react"
import styles     from "./scss/logo.module.scss"
import LogoBadge  from "./LogoBadge"
import CssModules from "react-css-modules"

const Logo = () => <div className={ styles.logoWrapper }>
  <div className={ styles.logoBadge }>
    <LogoBadge width={ 90 }
               height={ 103 }
               backgroundClass={ styles.logoBadgeSvgBg }
               foregroundClass={ styles.logoBadgeSvgFg }
    />
  </div>
  <div className={ styles.logoTextWrapper }>
    <h1 className={ styles.logoName }>Badge Trail</h1>
    <div className={ styles.logoTagline }>
      Find Your Path
    </div>
  </div>
</div>

export default CssModules(Logo)
