import React     from "react"
import PropTypes from "prop-types"

const LogoBadge = ({
  width,
  height,
  backgroundClass = "logo-fg",
  foregroundClass = "logo-bg",
}) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
           x="0px" y="0px"
           viewBox="0 0 144.84 167.22" style={ { enableBackground: "new 0 0 144.84 167.22" } }
           width={ width }
           height={ height }
>
  <style type=" text/css">
    {
      `'.logo-fg{ fill: " white" }
      .logo-bg{ fill: " black" }`
    }
  </style>
  <g>
    <path className={ backgroundClass } d="
           M72.6,13.42c17.01,0,34.02,0,51.04,0c6.2,0,9.78,3.55,9.78,9.76c0.01,31.33,0.03,62.65-0.02,93.98
c-0.01,6.29-2.8,11.19-8.3,14.34c-14.68,8.42-29.38,16.81-44.11,25.13c-5.55,3.13-11.28,3.07-16.81-0.06
c-14.73-8.32-29.43-16.71-44.09-25.15c-5.33-3.07-8.14-7.85-8.15-13.99c-0.06-31.53-0.03-63.07-0.02-94.6c0-5.9,3.56-9.4,9.44-9.41
C38.44,13.42,55.52,13.42,72.6,13.42z M93.94,70.08c3.09-3.08,6.01-5.91,8.83-8.82c1.56-1.61,2.95-3.34,5.57-2.17
c0.4,0.18,0.96,0.04,1.44-0.03c4.7-0.66,7.85-5,7.11-9.76c-0.69-4.4-5.1-7.48-9.65-6.75c-4.42,0.71-7.48,5.1-6.85,9.63
c0.11,0.79-0.18,1.91-0.72,2.46c-3.98,4.13-8.05,8.18-12.16,12.18c-0.46,0.45-1.47,0.74-2.09,0.58c-4.07-1.08-8.1-2.27-12.12-3.51
c-0.61-0.19-1.26-0.77-1.57-1.35c-1.42-2.66-4-3.94-6.69-3.2c-2.76,0.76-4.27,3.04-4.12,6.15c0.03,0.57,0.02,1.34-0.31,1.69
c-2.71,2.84-5.49,5.6-8.65,8.77c-3.71-3.84-7.23-7.53-10.81-11.16c-0.91-0.92-1.44-1.79-1.47-3.2c-0.06-2.87-2.44-4.96-5.31-5.01
c-3-0.06-5.28,1.92-5.68,4.92c-0.37,2.73,1.51,5.32,4.31,5.98c0.66,0.15,1.48,0.22,1.91,0.64c4.23,4.16,8.4,8.38,12.2,12.19
c-2.1,2.37-4.08,4.66-6.15,6.86c-0.29,0.31-1.11,0.3-1.62,0.18c-5.29-1.26-9.61,1.42-10.66,6.67c-0.67,3.36,0.76,6.83,3.58,8.66
c2.87,1.87,6.54,1.82,9.36-0.12c2.79-1.93,4.12-5.39,3.33-8.74c-0.25-1.05-0.6-2.08-0.83-2.9c2.29-2.35,4.45-4.57,6.74-6.92
c2.41,2.43,4.88,4.75,7.12,7.28c0.87,0.99,1.52,2.38,1.77,3.68c0.55,2.86,2.65,4.94,5.49,5c2.85,0.06,5.03-1.92,5.68-4.77
c0.28-1.25,0.91-2.6,1.79-3.5c5.5-5.65,11.09-11.2,16.71-16.72c0.51-0.5,1.61-0.83,2.28-0.65c4.26,1.15,8.49,2.43,12.69,3.78
c0.61,0.2,1.28,0.93,1.49,1.56c1.15,3.48,4.23,5.19,7.42,3.98c3.1-1.17,4.48-4.62,3.07-7.69c-1.36-2.97-4.82-4-7.92-2.42
c-0.51,0.26-1.2,0.53-1.69,0.39C102.55,72.72,98.43,71.44,93.94,70.08z"/>
    <path className={ foregroundClass } d="M93.94,70.08c4.49,1.36,8.62,2.64,12.77,3.82c0.49,0.14,1.18-0.13,1.69-0.39c3.1-1.58,6.56-0.55,7.92,2.42
c1.41,3.07,0.03,6.52-3.07,7.69c-3.19,1.21-6.27-0.5-7.42-3.98c-0.21-0.63-0.88-1.36-1.49-1.56c-4.2-1.35-8.43-2.63-12.69-3.78
c-0.67-0.18-1.77,0.15-2.28,0.65c-5.63,5.51-11.22,11.07-16.71,16.72c-0.88,0.91-1.51,2.25-1.79,3.5
c-0.65,2.84-2.83,4.82-5.68,4.77c-2.84-0.06-4.94-2.14-5.49-5c-0.25-1.3-0.89-2.69-1.77-3.68c-2.24-2.53-4.71-4.85-7.12-7.28
c-2.29,2.35-4.45,4.57-6.74,6.92c0.24,0.82,0.58,1.85,0.83,2.9c0.8,3.35-0.54,6.81-3.33,8.74c-2.82,1.94-6.48,1.99-9.36,0.12
c-2.82-1.83-4.25-5.31-3.58-8.66c1.05-5.24,5.37-7.92,10.66-6.67c0.51,0.12,1.33,0.13,1.62-0.18c2.08-2.2,4.05-4.49,6.15-6.86
c-3.79-3.81-7.96-8.03-12.2-12.19c-0.43-0.42-1.25-0.49-1.91-0.64c-2.8-0.66-4.67-3.24-4.31-5.98c0.4-3,2.68-4.97,5.68-4.92
c2.87,0.05,5.25,2.14,5.31,5.01c0.03,1.41,0.56,2.27,1.47,3.2c3.58,3.63,7.1,7.32,10.81,11.16c3.16-3.18,5.95-5.94,8.65-8.77
c0.33-0.35,0.34-1.12,0.31-1.69c-0.14-3.11,1.36-5.39,4.12-6.15c2.69-0.74,5.27,0.55,6.69,3.2c0.31,0.58,0.96,1.16,1.57,1.35
c4.02,1.25,8.06,2.44,12.12,3.51c0.62,0.16,1.62-0.13,2.09-0.58c4.11-4,8.18-8.05,12.16-12.18c0.53-0.55,0.83-1.67,0.72-2.46
c-0.63-4.52,2.43-8.92,6.85-9.63c4.56-0.73,8.96,2.35,9.65,6.75c0.75,4.76-2.41,9.1-7.11,9.76c-0.48,0.07-1.04,0.21-1.44,0.03
c-2.63-1.18-4.02,0.55-5.57,2.17C99.95,64.18,97.03,67,93.94,70.08z M55.4,78.89c2.98,2.99,5.79,5.83,8.64,8.63
c1.02,1,2.02,0.86,3.03-0.19c2.83-2.93,5.67-5.85,8.59-8.69c2.42-2.35,4.95-4.59,7.82-7.23c-4.43-1.28-8.04-2.37-11.69-3.34
c-0.43-0.11-1.01,0.42-1.54,0.6c-1.58,0.54-3.16,1.05-4.75,1.56c-0.65,0.21-1.49,0.21-1.93,0.63
C60.86,73.42,58.24,76.08,55.4,78.89z M42.3,95.47c0.04-3.35-2.21-5.78-5.39-5.83c-3.28-0.06-5.56,2.3-5.63,5.81
c-0.06,3.1,2.29,5.59,5.36,5.66C39.72,101.16,42.26,98.63,42.3,95.47z M114.04,50.9c-0.02-3.11-2.43-5.5-5.54-5.48
c-3.08,0.02-5.57,2.66-5.49,5.81c0.07,2.92,2.61,5.32,5.57,5.27C111.65,56.46,114.06,53.98,114.04,50.9z"/>
    <path className={ backgroundClass } d="M55.4,78.89c2.84-2.81,5.46-5.47,8.16-8.03c0.44-0.42,1.28-0.42,1.93-0.63c1.59-0.51,3.17-1.02,4.75-1.56
c0.53-0.18,1.11-0.72,1.54-0.6c3.65,0.97,7.27,2.06,11.69,3.34c-2.87,2.64-5.4,4.88-7.82,7.23c-2.92,2.84-5.76,5.76-8.59,8.69
c-1.01,1.05-2.01,1.19-3.03,0.19C61.19,84.72,58.38,81.88,55.4,78.89z"/>
    <path className={ foregroundClass } d="M42.3,95.47c-0.04,3.16-2.59,5.7-5.66,5.63c-3.07-0.07-5.42-2.55-5.36-5.66c0.07-3.5,2.36-5.86,5.63-5.81
C40.09,89.69,42.34,92.12,42.3,95.47z"/>
    <path className={ foregroundClass } d="M114.04,50.9c0.02,3.08-2.39,5.56-5.46,5.61c-2.97,0.05-5.5-2.35-5.57-5.27c-0.08-3.15,2.42-5.79,5.49-5.81
C111.61,45.4,114.02,47.78,114.04,50.9z"/>
  </g>
</svg>

LogoBadge.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  backgroundClass: PropTypes.string,
  foregroundClass: PropTypes.string,
}

export default LogoBadge