import { lazy } from "react"

const routes = [
  {
    path: "/",
    name: "home",
    label: "Home",
    weight: 0,
    menu: true,
    component: lazy(() => import("./components/pages/Home")),
    exact: true,
  },
  {
    path: "/about",
    name: "about",
    label: "About",
    weight: 1,
    menu: true,
    component: lazy(() => import("./components/pages/About")),
    exact: true,
  },
  {
    path: "/teachers",
    name: "teachers",
    label: "For Teachers",
    weight: 2,
    menu: true,
    component: lazy(() => import("./components/pages/ForTeachers")),
    exact: true,
  },
  {
    path: "/students",
    name: "students",
    label: "For Students",
    weight: 3,
    menu: true,
    component: lazy(() => import("./components/pages/ForStudents")),
    exact: true,
  },
  {
    path: "/administrators",
    name: "administrators",
    label: "For Administrators",
    weight: 4,
    menu: true,
    component: lazy(() => import("./components/pages/ForAdministrators")),
    exact: true,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    label: "Privacy Policy",
    menu: false,
    component: lazy(() => import("./components/pages/PrivacyPolicy")),
    exact: true,
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    label: "Terms of Service",
    menu: false,
    component: lazy(() => import("./components/pages/TermsOfService")),
    exact: true,
  },
]

export const getMenuRoutes = () => routes.filter(route => route.menu).sort((a, b) => {
  if (a.weight > b.weight) return 1
  if (a.weight < b.weight) return -1

  return 0
})

export default routes