import React      from "react"
import styles     from "./scss/footer.module.scss"
import CssModules from "react-css-modules"
import { Link }   from "react-router-dom"
import Pane  from "../Grid/pane"

const Footer = () => <Pane className={ styles.footer }>
  <div className={ styles.copyright }>
    &copy; 2019 Badge Trail, Llc. All Rights Reserved.
    | <Link to="/privacy-policy">Privacy Policy</Link> &bull; <Link to="/terms-of-service">Terms of Service</Link>
  </div>
</Pane>

export default CssModules(Footer)