import React      from "react"
import PropTypes  from "prop-types"
import cn         from "classname"
import styles     from "./scss/grid.module.scss"
import CssModules from "react-css-modules"
import Pane       from "./pane"

const processAreas = area => {
  if (Array.isArray(area)) {
    return area.map(a => `"${ a }"`).join("\n")
  }

  return `"${area}"`
}

const processStyles = ({
  gap,
  areas,
  columns,
  columnTemplate,
  rowTemplate,
  columnAlign = "start",
  rowAlign = "start",
  ...props
}) => {
  const style = {
    "--grid-column-align": columnAlign,
    "--grid-row-align": rowAlign,
  }

  if (areas) {
    style["--grid-template-areas"] = processAreas(areas)
  }

  if (columns) {
    style["--grid-columns-total"] = columns
  }

  if (gap) {
    style["--grid-gap"] = gap
  }

  if (columnTemplate) {
    style["--grid-column-template"] = columnTemplate
  }

  if (rowTemplate) {
    style["--grid-row-template"] = rowTemplate
  }

  return Object.assign(
          style,
          ...([ "small", "medium", "large", "xLarge" ].map(
                  size => ({
                    [`--grid-gap--${ size }`]: props[`${ size }Gap`] || style["--grid-gap"],
                    [`--grid-columns-total--${ size }`]: props[`${ size }Columns`] || style["--grid-columns-total"],
                    [`--grid-column-template--${ size }`]: props[`${ size }ColumnTemplate`] || style["--grid-column-template"],
                    [`--grid-row-template--${ size }`]: props[`${ size }lRowTemplate`] || style["--grid-row-template"],
                    [`--grid-column-align--${ size }`]: props[`${ size }ColumnAlign`] || style["--grid-column-align"],
                    [`--grid-row-align--${ size }`]: props[`${ size }RowAlign`] || style["--grid-row-align"],
                    [`--grid-template-areas--${ size }`]: props[`${ size }Areas`] && processAreas(props[`${ size }Areas`]) || style["--grid-template-areas"],
                  }),
          )),
  )
}

const Index = ({
  className,
  children,
  gap,
  areas = "unset",
  columns,
  columnTemplate,
  rowTemplate = "min-content",
  columnAlign = "unset",
  rowAlign = "unset",
  smallGap,
  smallAreas,
  smallColumns,
  smallColumnTemplate,
  smallRowTemplate,
  smallColumnAlign,
  smallRowAlign,
  mediumGap,
  mediumAreas,
  mediumColumns,
  mediumColumnTemplate,
  mediumRowTemplate,
  mediumColumnAlign,
  mediumRowAlign,
  largeGap,
  largeAreas,
  largeColumns,
  largeColumnTemplate,
  largeRowTemplate,
  largeColumnAlign,
  largeRowAlign,
  xLargeGap,
  xLargeAreas,
  xLargeColumns,
  xLargeColumnTemplate,
  xLargeRowTemplate,
  xLargeColumnAlign,
  xLargeRowAlign,
  ...props
}) => {
  const classes = [ styles.grid, className ]
  const style = processStyles({
    gap,
    areas,
    columns,
    columnTemplate,
    rowTemplate,
    columnAlign,
    rowAlign,
    smallGap,
    smallAreas,
    smallColumns,
    smallColumnTemplate,
    smallRowTemplate,
    smallColumnAlign,
    smallRowAlign,
    mediumGap,
    mediumAreas,
    mediumColumns,
    mediumColumnTemplate,
    mediumRowTemplate,
    mediumColumnAlign,
    mediumRowAlign,
    largeGap,
    largeAreas,
    largeColumns,
    largeColumnTemplate,
    largeRowTemplate,
    largeColumnAlign,
    largeRowAlign,
    xLargeGap,
    xLargeAreas,
    xLargeColumns,
    xLargeColumnTemplate,
    xLargeRowTemplate,
    xLargeColumnAlign,
    xLargeRowAlign,
  })

  return <div { ...props } className={ cn(classes) } style={ style }>{ children }</div>
}

Index.propTypes = {
  className: PropTypes.any,
  children: PropTypes.node,
  gap: PropTypes.string,
  areas: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
  columns: PropTypes.number,
  columnTemplate: PropTypes.string,
  rowTemplate: PropTypes.string,
  columnAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  rowAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  smallGap: PropTypes.string,
  smallColumns: PropTypes.number,
  smallColumnTemplate: PropTypes.string,
  smallRowTemplate: PropTypes.string,
  smallColumnAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  smallRowAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  smallAreas: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
  mediumGap: PropTypes.string,
  mediumColumns: PropTypes.number,
  mediumColumnTemplate: PropTypes.string,
  mediumRowTemplate: PropTypes.string,
  mediumColumnAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  mediumRowAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  mediumAreas: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
  largeGap: PropTypes.string,
  largeColumns: PropTypes.number,
  largeColumnTemplate: PropTypes.string,
  largeRowTemplate: PropTypes.string,
  largeColumnAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  largeRowAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  largeAreas: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
  xLargeGap: PropTypes.string,
  xLargeColumns: PropTypes.number,
  xLargeColumnTemplate: PropTypes.string,
  xLargeRowTemplate: PropTypes.string,
  xLargeColumnAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  xLargeRowAlign: PropTypes.oneOf([ "start", "center", "end", "stretch" ]),
  xLargeAreas: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]),
}

export default CssModules(Index)

export { Pane }