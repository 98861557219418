import React from "react"
import ReactDom from "react-dom"
import App from "./components/App.jsx"
import { applyMiddleware, createStore } from "redux"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import rootReducers from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducers, {}, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

ReactDom.render(
  <Provider store={ store }>
    <App/>
  </Provider>,
  document.getElementById("root"),
)