import React      from "react"
import PropTypes  from "prop-types"
import CssModules from "react-css-modules"
import cn         from "classname"
import styles     from "./scss/pane.module.scss"

const processStyles = ({
  verticalAlign = "flex-start",
  horizontalAlign = "stretch",
  rowStart = "auto",
  rowEnd = "auto",
  columnStart = "auto",
  columnEnd = "auto",
  area = "unset",
  ...props
}) => {
  const style = {
    "--pane-vertical-align": verticalAlign,
    "--pane-horizontal-align": horizontalAlign,
    "--pane-row-start": rowStart,
    "--pane-row-end": rowEnd,
    "--pane-column-start": columnStart,
    "--pane-column-end": columnEnd,
    "--pane-area": area,
  }

  return Object.assign(
          style,
          ...([ "small", "medium", "large", "xLarge" ].map(size => ({
            [`--pane-vertical-align--${ size }`]: props[`${ size }VerticalAlign`] || style["--pane-vertical-align"],
            [`--pane-horizontal-align--${ size }`]: props[`${ size }HorizontalAlign`] || style["--pane-horizontal-align"],
            [`--pane-column-start--${ size }`]: props[`${ size }ColumnStart`] || style["--pane-column-start"],
            [`--pane-column-end--${ size }`]: props[`${ size }ColumnEnd`] || style["--pane-column-end"],
            [`--pane-row-start--${ size }`]: props[`${ size }RowStart`] || style["--pane-row-start"],
            [`--pane-row-end--${ size }`]: props[`${ size }RowEnd`] || style["--pane-row-end"],
            [`--pane-area--${ size }`]: props[`${ size }Area`] || style["--pane-area"],
          }))),
  )
}

const Pane = ({
  className,
  children,
  verticalAlign = "flex-start",
  horizontalAlign = "stretch",
  rowStart = "auto",
  rowEnd = "auto",
  columnStart = "auto",
  columnEnd = "auto",
  area = "unset",
  smallVerticalAlign,
  smallHorizontalAlign,
  smallRowStart,
  smallRowEnd,
  smallColumnStart,
  smallColumnEnd,
  smallArea,
  mediumVerticalAlign,
  mediumHorizontalAlign,
  mediumRowStart,
  mediumRowEnd,
  mediumColumnStart,
  mediumColumnEnd,
  mediumArea,
  largeVerticalAlign,
  largeHorizontalAlign,
  largeRowStart,
  largeRowEnd,
  largeColumnStart,
  largeColumnEnd,
  largeArea,
  xLargeVerticalAlign,
  xLargeHorizontalAlign,
  xLargeRowStart,
  xLargeRowEnd,
  xLargeColumnStart,
  xLargeColumnEnd,
  xLargeArea,
  ...props
}) => {
  const classes = [ className, styles.pane ]
  const style = processStyles({
    verticalAlign,
    horizontalAlign,
    rowStart,
    rowEnd,
    columnStart,
    columnEnd,
    area,
    smallVerticalAlign,
    smallHorizontalAlign,
    smallRowStart,
    smallRowEnd,
    smallColumnStart,
    smallColumnEnd,
    smallArea,
    mediumVerticalAlign,
    mediumHorizontalAlign,
    mediumRowStart,
    mediumRowEnd,
    mediumColumnStart,
    mediumColumnEnd,
    mediumArea,
    largeVerticalAlign,
    largeHorizontalAlign,
    largeRowStart,
    largeRowEnd,
    largeColumnStart,
    largeColumnEnd,
    largeArea,
    xLargeVerticalAlign,
    xLargeHorizontalAlign,
    xLargeRowStart,
    xLargeRowEnd,
    xLargeColumnStart,
    xLargeColumnEnd,
    xLargeArea,
  })

  return <div { ...props } className={ cn(classes) } style={ style }>
    { children }
  </div>
}

Pane.propTypes = {
  className: PropTypes.any,
  children: PropTypes.node,
  verticalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  horizontalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  rowStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  rowEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  columnStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  columnEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  area: PropTypes.string,
  smallVerticalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  smallHorizontalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  smallRowStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  smallRowEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  smallColumnStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  smallColumnEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  smallArea: PropTypes.string,
  mediumVerticalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  mediumHorizontalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  mediumRowStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  mediumRowEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  mediumColumnStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  mediumColumnEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  mediumArea: PropTypes.string,
  largeVerticalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  largeHorizontalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  largeRowStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  largeRowEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  largeColumnStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  largeColumnEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  largeArea: PropTypes.string,
  xLargeVerticalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  xLargeHorizontalAlign: PropTypes.oneOf([ "flex-start", "flex-end", "center", "stretch" ]),
  xLargeRowStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  xLargeRowEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  xLargeColumnStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  xLargeColumnEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  xLargeArea: PropTypes.string,
}

export default CssModules(Pane)