import React     from "react"
import styles    from "./scss/header.module.scss"
import Logo      from "./Logo"
import Nav       from "./Nav"
import PropTypes from "prop-types"
import Pane      from "../Grid/pane"
import Container from "../Container"

const Header = ({ routes = [] }) => <Pane className={ styles.header }>
  <Container padding="0">
    <Logo/>
    <Nav routes={ routes }/>
  </Container>
</Pane>

Header.propTypes = {
  routes: PropTypes.array,
}

export default Header