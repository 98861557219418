import React      from "react"
import PropTypes  from "prop-types"
import CssModules from "react-css-modules"
import styles     from "./scss/container.module.scss"
import cn         from "classname"

const Container = ({ fluid = false, children = [], className = null, padding = "20px 10px" }) => {
  const classNames = [ styles.container, className ]
  const style = { "--container-padding": padding }

  if (fluid) {
    classNames.push(styles.fluid)
  }

  return <section className={ cn(classNames) } style={ style }>{ children }</section>
}

Container.propTypes = {
  fluid: PropTypes.bool,
  className: PropTypes.any,
  children: PropTypes.arrayOf(PropTypes.node),
  padding: PropTypes.string,
}

export default CssModules(Container)