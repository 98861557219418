import React, { useState } from "react"
import { NavLink }         from "react-router-dom"
import CssModules          from "react-css-modules"
import styles              from "./scss/nav.module.scss"
import PropTypes           from "prop-types"
import cn                  from "classname"

const Nav = ({ routes = [] }) => {
  const [ active, setActive ] = useState(false)

  const classes = [ styles.nav ]

  if (active) {
    classes.push(styles.active)
  }

  return <div className={ cn(classes) } onClick={ () => setActive(!active) }>
    <ul>
      {
        routes.map(({ name, path, label }) => <li key={ name }>
          <NavLink to={ path } activeClassName={ styles.active } isActive={ (match, currentLocation) =>
                  match && match.url === currentLocation.pathname
          }>{ label }</NavLink>
        </li>)
      }
    </ul>
  </div>
}

Nav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
}

export default CssModules(Nav)